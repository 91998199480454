import React from "react";

function renewqr() {
  const message = `This QR code has expired..!`;
  const message2 = `The vehicle owner needs to renew it.`;

  return (
    <div>
      <div className="text-center mt-5 mb-4">
        <img
          src="assests/image/payment_failed.svg"
          alt="Payment Failed - QR Code Inactive"
          className="company-pic"
        />
        <h2 className="dark-message mt-3" style={{ color: "#4E4E4E" }}>
          {message}
        </h2>
        <p className="text-center">{message2}</p>
      </div>
    </div>
  );
}

export default renewqr;
