import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { apiUrl } from "./config";
import { toast, ToastContainer, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";

function RenewCheckout() {
  const navigate = useNavigate();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const userLoginId = query.get("userLoginId");
  const vehicleOwnerMasterDetailId = query.get("vehicleOwnerMasterDetailId");
  const location = useLocation();
  const { productid, rate, VehicleBarcode } = location.state || {};
  console.log(rate, "NEEWEWEEE");
  console.log(productid, "NEEWEWEEE");
  console.log(VehicleBarcode, "NEEWEWEEE");

  const stickerType =
    productid === 1
      ? "Car Sticker"
      : productid === 2
      ? "Bike Sticker"
      : "Unknown Sticker";

  const iconClass =
    productid === 1 ? "fas fa-car" : productid === 2 ? "fas fa-bicycle" : "";

  const [personalInfo, setPersonalInfo] = useState({});
  const [vehicleList, setVehicleList] = useState([]);
  const [loading, setLoading] = useState(false);

  const rateNumber = Number(rate) || 0;

  const [promoCode, setPromoCode] = useState("");
  const [promoApplied, setPromoApplied] = useState(false);
  const [discount, setDiscount] = useState(0);
  const [grossAmount, setGrossAmount] = useState(rateNumber);
  const [gstAmount, setGstAmount] = useState(rateNumber * 0.18);
  const [netAmount, setNetAmount] = useState(rateNumber + rateNumber * 0.18);

  const [newNetAmount, setNewNetAmount] = useState(0);

  const newNetAmount2 = promoApplied
    ? rateNumber - discount + gstAmount
    : rateNumber + gstAmount;

  // Payment Integration

  const [formData, setFormData] = useState({
    name: "",
    mobile: "",
    email: "",
    address: "",
    pincode: "",
    state: "",
    city: "",
    guid: VehicleBarcode,
    amount: newNetAmount2,
    productrate: rate,
    productid,
  });

  useEffect(() => {
    if (personalInfo) {
      setFormData((prev) => ({
        ...prev,
        name: personalInfo.VehicleOwnerName || "",
        mobile: personalInfo.VehicleOwnerMobile || "",
        email: personalInfo.VehicleOwnerEmail || "",
        address: personalInfo.VehicleOwnerAddress || "",
        pincode: personalInfo.VehicleOwnerPincode || "",
        state: personalInfo.VehicleOwnerState || "",
        city: personalInfo.VehicleOwnerCity || "",
      }));
    }
  }, [personalInfo]);

  const applyPromoCode = async () => {
    if (!promoCode) return;

    setLoading(true);

    try {
      const response = await axios.post(`${apiUrl}/promocode`, {
        coupon: promoCode,
        totalAmount: rateNumber,
      });

      if (response.data.Status === "Success") {
        const discountPercentage = Number(response.data.percentage) || 0; // Ensure it's a number
        let discountAmount = (rateNumber * discountPercentage) / 100;

        discountAmount = Number(discountAmount.toFixed(2)); // Ensure it's a number with 2 decimal places

        const newGrossAmount = rateNumber - discountAmount;
        const newGstAmount = Number((newGrossAmount * 0.18).toFixed(2));
        const newNetAmountCalculated = Number(
          (newGrossAmount + newGstAmount).toFixed(2)
        );

        setDiscount(discountAmount);
        setGrossAmount(newGrossAmount);
        setGstAmount(newGstAmount);
        setNewNetAmount(newNetAmountCalculated);

        // Update formData with correct numbers
        setFormData((prev) => ({
          ...prev,
          amount: newNetAmountCalculated,
          discount: discountAmount,
        }));

        setPromoApplied(true);
        setLoading(false);
      } else {
        alert("Invalid Promo Code");
        setLoading(false);
      }
    } catch (error) {
      console.error("Error applying promo code:", error);
      alert("Failed to apply promo code. Please try again.");
      setLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);

    try {
      console.log("Sending data:", formData);
      const response = await axios.post(`${apiUrl}/checkout/renew`, formData);
      const data = response.data;

      if (data.Status === "Success") {
        const amountInPaise = Math.round(data.data.amount * 100, 2); // Round and convert to paise
        const paymentOptions = {
          key: "rzp_test_2RwPgdzjFdXB0m",
          amount: amountInPaise, // Razorpay expects the amount in paise (multiplied by 100)
          currency: "INR",
          name: "QR Genie",
          description: "Test Transaction",
          capture_mode: 1,
          order_id: data.razorpayResponce.order_id,
          handler: async (paymentResponse) => {
            try {
              setLoading(true);
              const paymentStatusResponse = await axios.post(
                `${apiUrl}/paymentstatus/renew`,
                {
                  razorpay_id: paymentResponse.razorpay_payment_id || null,
                  order_id: data.data.orderID,
                  razorpay_payment_id: paymentResponse.razorpay_payment_id,
                  razorpay_order_id: paymentResponse.razorpay_order_id,
                  razorpay_signature: paymentResponse.razorpay_signature,
                  amount: amountInPaise / 100, // Use the same net amount here
                  currency: "INR",
                  status: "Success",
                  guid: VehicleBarcode,
                }
              );

              if (paymentStatusResponse.data.Status === "Success") {
                navigate("/userdetail");
              }
            } catch (error) {
              console.error("Payment Status API Error:", error);
            } finally {
              setLoading(false);
            }
          },
          prefill: {
            name: formData.name,
            email: formData.email,
            contact: formData.mobile,
          },
          notes: {
            address: formData.address,
          },
          theme: { color: "#F37254" },
          modal: {
            ondismiss: async () => {
              console.log("Payment dismissed by user");

              // Log the dismissed payment attempt as failed
              try {
                await axios.post(`${apiUrl}/paymentstatus`, {
                  razorpay_id: null,
                  order_id: data.data.orderID,
                  amount: amountInPaise / 100, // Use the same net amount here
                  currency: "INR",
                  status: "Failed",
                  failure_reason: "Payment dismissed by user",
                });
                console.log("Dismissed payment logged as failed");
              } catch (error) {
                console.error(
                  "Failed to log dismissed payment as failed:",
                  error
                );
              }

              navigate("/payment_failed");
            },
          },
        };

        const rzp = new window.Razorpay(paymentOptions);
        rzp.open();
      }
    } catch (error) {
      console.error("Checkout API Error:", error);
    }
  };

  const removePromoCode = () => {
    setPromoCode("");
    setPromoApplied(false);
    setDiscount(0);
    setGrossAmount(rateNumber);
    setGstAmount(rateNumber * 0.18);
    setNetAmount(rateNumber + rateNumber * 0.18);
  };

  useEffect(() => {
    const storedPersonalInfo = localStorage.getItem("personalInfo");
    const storedVehicleList = localStorage.getItem("vehicleList");

    if (storedPersonalInfo) {
      setPersonalInfo(JSON.parse(storedPersonalInfo));
    }
    if (storedVehicleList) {
      const allVehicles = JSON.parse(storedVehicleList);
      // Filter only the vehicle with matching VehicleOwnerMasterDetailId
      const filteredVehicle = allVehicles.find(
        (vehicle) =>
          vehicle.VehicleOwnerMasterDetailId?.toString() ===
          vehicleOwnerMasterDetailId
      );
      setVehicleList(filteredVehicle ? [filteredVehicle] : []);
    }
    setLoading(false);
  }, [vehicleOwnerMasterDetailId]);

  const formatDate = (dateString) => {
    if (!dateString) return "N/A";
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  return (
    <div className="container mt-4">
      <input type="hidden" name="productid" value={productid} />
      <input type="hidden" name="rate" value={rate} />
      <h2 className="mb-4">Renew Checkout</h2>

      <div className="row">
        <div className="col-lg-8">
          {vehicleList.length > 0 ? (
            vehicleList.map((vehicle, index) => (
              <div
                className="card mb-4 h-auto"
                key={vehicle.VehicleOwnerMasterDetailId}
              >
                <div className="card-header">
                  Vehicle Information {index + 1}
                </div>
                <div className="card-body">
                  <form>
                    <div className="mb-3 row">
                      <div className="col-6">
                        <label
                          htmlFor={`vehiclePlateNumber-${index}`}
                          className="col-12 col-form-label"
                        >
                          License Plate Number:
                        </label>
                        <input
                          type="text"
                          readOnly
                          className="form-control"
                          id={`vehiclePlateNumber-${index}`}
                          value={vehicle.vehiclePlateNumber || "N/A"}
                        />
                      </div>

                      <div className="col-6">
                        <label
                          htmlFor={`emergencyContactNumber-${index}`}
                          className="col-12 col-form-label"
                        >
                          Emergency Contact Number:
                        </label>
                        <input
                          type="text"
                          readOnly
                          className="form-control"
                          id={`emergencyContactNumber-${index}`}
                          value={vehicle.emergencyContactNumber || "N/A"}
                        />
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            ))
          ) : (
            <div>No vehicle found for the provided ID.</div>
          )}

          <div className="card h-auto mb-4">
            <div className="card-header">Personal Information</div>
            <div className="card-body">
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-6 mb-3">
                    <label className="form-label fw-semibold">Name:</label>
                    <input
                      type="text"
                      readOnly
                      className="form-control"
                      value={personalInfo.VehicleOwnerName || "N/A"}
                    />
                  </div>
                  <div className="col-md-6 mb-3">
                    <label className="form-label fw-semibold">DOB:</label>
                    <input
                      type="text"
                      readOnly
                      className="form-control"
                      value={formatDate(personalInfo.VehicleOwnerDOB)}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6 mb-3">
                    <label className="form-label fw-semibold">
                      Blood Group:
                    </label>
                    <input
                      type="text"
                      readOnly
                      className="form-control"
                      value={personalInfo.VehicleOwnerBloodGroup || "N/A"}
                    />
                  </div>
                  <div className="col-md-6 mb-3">
                    <label className="form-label fw-semibold">Email:</label>
                    <input
                      type="email"
                      readOnly
                      className="form-control"
                      value={personalInfo.VehicleOwnerEmail || "N/A"}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6 mb-3">
                    <label className="form-label fw-semibold">
                      Phone Number:
                    </label>
                    <input
                      type="text"
                      readOnly
                      className="form-control"
                      value={personalInfo.VehicleOwnerMobile || "N/A"}
                    />
                  </div>
                  <div className="col-md-6 mb-3">
                    <label className="form-label fw-semibold">Address:</label>
                    <input
                      type="text"
                      readOnly
                      className="form-control"
                      value={personalInfo.VehicleOwnerAddress || "N/A"}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6 mb-3">
                    <label className="form-label fw-semibold">City:</label>
                    <input
                      type="text"
                      readOnly
                      className="form-control"
                      value={personalInfo.VehicleOwnerCity || "N/A"}
                    />
                  </div>
                  <div className="col-md-6 mb-3">
                    <label className="form-label fw-semibold">State:</label>
                    <input
                      type="text"
                      readOnly
                      className="form-control"
                      value={personalInfo.VehicleOwnerState || "N/A"}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6 mb-3">
                    <label className="form-label fw-semibold">Pincode:</label>
                    <input
                      type="text"
                      readOnly
                      className="form-control"
                      value={personalInfo.VehicleOwnerPincode || "N/A"}
                    />
                  </div>
                </div>

                <button className="Login_btn" type="submit">
                  Place Order
                </button>
              </form>
            </div>
          </div>
        </div>
        <div className="col-lg-4">
          <form>
            <div className="border p-3">
              <h5 className="br-b">Vehicle Stickers</h5>
              <div className="d-flex justify-content-between mb-3 mt-3">
                <div className="d-flex align-items-center">
                  {iconClass && (
                    <i
                      className={iconClass}
                      style={{ marginRight: "10px" }}
                    ></i>
                  )}
                  <h5>{stickerType} x 1</h5>
                </div>
              </div>

              <div className="d-flex justify-content-between">
                <div>Total Amount</div>
                <div>₹{rateNumber.toFixed(2)}</div>
              </div>

              {/* Promo Code Input */}
              <div className="promo-section mt-3">
                <div className="position-relative">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Have a promo code?"
                    value={promoCode}
                    onChange={(e) => setPromoCode(e.target.value)}
                    disabled={promoApplied}
                    style={{ paddingRight: "60px" }}
                  />
                  {!promoApplied ? (
                    <span
                      onClick={applyPromoCode}
                      style={{
                        position: "absolute",
                        right: "10px",
                        top: "50%",
                        transform: "translateY(-50%)",
                        color: "#EF4F5F",
                        cursor: "pointer",
                        fontWeight: "600",
                        paddingRight: "20px",
                      }}
                    >
                      Apply
                    </span>
                  ) : (
                    <span
                      onClick={removePromoCode}
                      style={{
                        position: "absolute",
                        right: "10px",
                        top: "50%",
                        transform: "translateY(-50%)",
                        color: "#EF4F5F",
                        cursor: "pointer",
                        fontWeight: "600",
                        paddingRight: "20px",
                      }}
                    >
                      Remove
                    </span>
                  )}
                </div>
              </div>

              {/* Promo Discount Display */}
              {promoApplied && (
                <div className="promo-discount mt-3">
                  <span>
                    Promo Code Discount -{" "}
                    <span
                      style={{
                        color: "white",
                        backgroundColor: "#EF4F5F",
                        fontWeight: "600",
                        padding: "3px",
                        borderRadius: "5px",
                      }}
                    >
                      {promoCode}
                    </span>
                  </span>
                  <span className="float-end">
                    ₹<strong>{discount.toFixed(2)}</strong>
                  </span>
                </div>
              )}

              {/* Gross Amount */}
              <div className="d-flex justify-content-between mt-2">
                <div>Gross Amount</div>
                <div>₹{Number(grossAmount).toFixed(2)}</div>
              </div>

              {/* GST (18%) */}
              <div className="d-flex justify-content-between mt-2">
                <div>GST (18%)</div>
                <div>₹{Number(gstAmount).toFixed(2)}</div>
              </div>

              {/* Net Amount (After Discount) */}
              <div className="d-flex justify-content-between mt-2">
                <div>
                  <strong>Net Amount (After Discount)</strong>
                </div>
                <div>
                  <strong>₹{Number(newNetAmount2).toFixed(2)}</strong>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      {loading && (
        <div className="loader-overlay">
          <div className="loader dots">
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      )}
    </div>
  );
}

export default RenewCheckout;
